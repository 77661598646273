@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  .letter {
    // position: absolute;
    @include type_title;
    font-variation-settings: 'wght' 200;
    pointer-events: none;
    transition: transform 0.25s $easeOutBack, opacity 0.25s $easeInOutCubic;
    user-select: none;
  }
  
  .word {
    @include type_title;
    font-size: 40px;
    font-variation-settings: 'wght' 200;
  }

  .border {
    position: absolute;
    pointer-events: none;
    width: 60%;
    height: 40%;
    top: 30%;
    left: 20%;
    border: 3px solid white;
    opacity: 0.2;
    transition: transform 0.25s $easeOutBack, opacity 0.25s $easeInOutCubic;
  }
  
  &:hover {
    .border {
      transform: scale(1.2);
      opacity: 0.4;
    }
  }

  &.disabled {
    pointer-events: none;
    
    .border, .letter {
      opacity: 0;
    }
  }
}