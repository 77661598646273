@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.minter {
  position: relative;
  width: 80%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: $spacing-m 0 $spacing-s;
  flex-shrink: 0;

  @include device-m {
    width: 100%;
    margin: $spacing-s 0 $spacing-s;
  }

  .label {
    @include type_label;
    margin: $spacing-s 0 $spacing-s;
    padding: $spacing-s $spacing-m;
    text-align: center;
    // text-shadow: 0px 0px 8px white;

    &.error {
      color: white !important;
      background-color: rgb(213, 27, 27);
      border-radius: 20px;
      margin: $spacing-m 0 $spacing-s;
    }

    &.success {
      color: #333 !important;
      background-color: rgb(6, 240, 6);
      border-radius: 20px;
      margin: $spacing-m 0 $spacing-s;
    }
  }
}