@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.pathWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;

  .text {
    position: absolute;
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    @include type_title();
    font-size: 48px;
  }

  .svg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    // height: 100%;
    z-index: 111;
    overflow: hidden;

    .path {
      pointer-events: none;
    }

    .label {
      @include type_title();
      color: white;
      font-size: 48px;
    }

    @include device-m {
      .path:nth-child(2) {
        opacity: 1;
      }
    }
  }
}