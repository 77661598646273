@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

$desktopGutter: $spacing-xl;
$mobileGutter: $spacing-m;

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .closeButton {
    position: absolute;
    top: 30px;
    right: $desktopGutter;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: black;
    z-index: $layer-3;
    cursor: pointer;

    @include device-m {
      right: $mobileGutter;
    }

    &::after,
    &::before {
      content: ' ';
      position: absolute;
      height: 2px;
      width: 20px;
      // border-radius: 50%;
      background-color: white;
      top: 50%;
      left: 50%;
      margin: -1px 0 0 -10px;
      transform: rotate(-45deg);
      transition: opacity 0.25s $easeInCubic, transform 0.25s $easeOutCubic 0s;
    }

    &::before {
      transform: rotate(45deg);
    }

    &:hover {
      &::after {
        transform: rotate(45deg);
      }
      &::before {
        transform: rotate(135deg);
      }
    }
  }

  .closedContent {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    @include type_title;
    cursor: pointer;

    @include device-m {
      display: none;
    }
  }
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;

  .title {
    @include type_title;
    text-align: left;
    margin: $spacing-xl $desktopGutter $spacing-l;
    line-height: 30px;
    position: relative;
    align-self: flex-start;

    @include device-m {
      margin: $spacing-xl $mobileGutter $spacing-l;
    }
  }

  p {
    @include type_par_xl();
    margin: 0 $desktopGutter $spacing-m;

    @include device-m {
      margin: 0 $mobileGutter $spacing-m;
    }
  }

  .linksWrapper {
    margin: $spacing-m 20px $spacing-xl;
  }
}