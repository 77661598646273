@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  @include type_title;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: center center;
    flex-shrink: 0;
    flex-grow: 0;

    .content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      flex-grow: 0;
      width: 100%;
      height: 100px;
      transition: font-variation-settings 0.1s $easeOutCubic;

      .title {
        position: relative;
        height: 30px;
        line-height: 30px;
        padding-right: 0px;
        transition: padding 0.25s $easeInOutCubic;
        white-space: nowrap;
        
        .arrow {
          position: absolute;
          top: 0;
          right: 0;
          height: 30px;
          width: 30px;
          opacity: 0;
          transition: opacity 0.25s $easeInOutCubic;
        }
      }
    }
  }

  // &.isClosed {
  //   .center {
  //     .content {
  //       .title {
  //         padding-right: 40px;
  //         .arrow {
  //           opacity: 1;
  //         }
  //       }
  //     }
  //   }
  // }

  .arrow {
    display: inline;
    transform-origin: center center;
    transition: transform 0.25s $easeOutBack 0.25s;

    &.expanded {
      transform: rotate(180deg);
    }

    &.directionUp {
      transform: rotate(180deg);
      &.expanded {
        transform: rotate(0deg);
      }
    }
  }

  .expandLabel {
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: $spacing-m;
    @include type_par();
    opacity: 0;
    text-align: center;
    pointer-events: none;
    transition: opacity 0.25s $easeOutCubic, transform 0.25s $easeInCubic;
    transform: translate3d(0, -30px, 0) scale(0.9);
  }

  &:hover {
    .expandLabel {
      transition: opacity 0.25s $easeOutCubic, transform 0.25s $easeOutCubic;
      opacity: 1;
      transform: translate3d(0, -30px, 0) scale(1);
    } 
  }
}