@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.container {
  position: absolute;

  .content {
    position: absolute;
    transform: translate3d(0, 0, 0);
  }
}