@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import './variables';
@import './mixins';

@mixin type_title {
  // font-family: 'Quicksand', sans-serif;
  font-family: 'Inter', sans-serif;
  // font-family: 'Changa', sans-serif;
  font-weight: 200;
  font-variation-settings: 'wght' 200;
  font-size: 32px;
  line-height: 0px;
  user-select: none;

  @include device-m {
    font-size: 26px;
  }
}

@mixin type_par {
  // font-family: 'Quicksand', sans-serif;
  font-family: 'Inter', sans-serif;
  // font-family: 'Changa', sans-serif;
  font-weight: 200;
  font-variation-settings: 'wght' 200;
  // font-size: 14px;
  // line-height: 20px;
  font-size: 16px;
  line-height: 28px;
  user-select: none;

  @include device-m {
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin type_par_xl {
  @include type_par();
  font-size: 22px;
  line-height: 36px;

}

@mixin type_button {
  // font-family: 'Quicksand', sans-serif;
  font-family: 'Inter', sans-serif;
  // font-family: 'Changa', sans-serif;
  font-weight: 400;
  font-variation-settings: 'wght' 400;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  user-select: none;
  cursor: pointer;
  color: $color-blue;
  &:hover {
    // font-weight: 600;
    // font-variation-settings: 'wght' 600;
    color: $color-white !important;
  }
  @include device-m {
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin type_button_s {
  // font-family: 'Quicksand', sans-serif;
  font-family: 'Inter', sans-serif;
  // font-family: 'Changa', sans-serif;
  font-weight: 400;
  font-variation-settings: 'wght' 400;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 20px;
  user-select: none;
  cursor: pointer;
  color: $color-blue;
  &:hover {
    // font-weight: 600;
    // font-variation-settings: 'wght' 600;
    color: $color-white !important;
  }
}

@mixin type_label {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-variation-settings: 'wght' 400;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 20px;
  
  @include device-m {
    font-size: 10px;
    line-height: 14px;
  }
}