@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.slide {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // cursor: pointer;
  background-position: center center;
  background-size: cover;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    transition: opacity 0.5s $easeInOutCubic;
    // pointer-events: none;
  }

  &:hover {
    .overlay {
      opacity: 0.8;
    }
  }

  @include device-m {
    .overlay {
      opacity: 0.4;
    }
  }
  &:hover {
    .overlay {
      opacity: 0.4;
    }
  }
}