@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.container {
  position: absolute;
  overflow: hidden;
}

.content {
  position: absolute;
  top: 0;
  left: 0; 
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  // opacity: 0;
  // transition: opacity 0.25s $easeInOutCubic;
  // transition-delay: 0s;
  
  &.visible {
    // opacity: 1;
    // transition-delay: 0.25s;
  }
}