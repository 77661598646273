@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.loader {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  // border: 3px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  // position: relative;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  border: 3px solid;
  animation: buttonSpinnerRotation 1s linear infinite;
  transition: opacity 0.25s $easeInOutCubic;
}

@keyframes buttonSpinnerRotation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
} 