@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: grab;
  
  &.grabbing {
    cursor: grabbing
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;

    .arrow {
      pointer-events: none;
    }
  }
}