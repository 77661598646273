@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.svg {
  position: relative;
  overflow: visible;
  transform-origin: center center;
  transition: transform 0.25s $easeOutBack 0.25s, opacity 0.25s $easeInOutCubic;

  path,
  circle {
    transition: transform 0.25s $easeInOutCubic, opacity 0.25s $easeInOutCubic;
  }
}
