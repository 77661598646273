@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.CircleButton {
  position: absolute;
  display: block;
  width: 0px;
  height: 0px;
  pointer-events: none;
  // background: black
  // border-radius: 50%

  .ButtonWrapper {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(-50%, -50%, 0);
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  .LetterWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    pointer-events: none;
  }

  .Letter {
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
    // font-family: 'Fredoka One';
    @include type_par_xl();
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    transition-duration: 0.25s;
    mix-blend-mode: difference;

    &:not(.isHovering) {
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
      transition-duration: 0.25s;
    }

    .LetterInset {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate3d(-50%, -50%, 0);
    }
  }
}