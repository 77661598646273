@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.container {
  position: absolute;
  z-index: $layer-3;

  .content {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transform-origin: 90% 50%;
    z-index: 10;
    opacity: 0;

    transition: transform $easeOutCubic .5s, opacity $easeOutCubic .1s;

    cursor: default;
    @include type_title;
    padding-left: 10%;
    
    &:nth-child(1) {
      opacity: 1;
    }

    @for $i from 2 through 6 {
      &:nth-child(#{$i}) {
        z-index: #{90 - $i};
        transition: transform $easeOutCubic #{(.5s / 6) * (6 - $i)}, opacity $easeOutCubic .15s;
      }
    }
    
    &:hover:not(:nth-child(1)) {
      background-color: $color-blue !important;
      z-index: 100;
      cursor: pointer;
      color: $color-white !important;
      font-variation-settings: 'wght' 400;
    }
  }

  &:hover:not(:nth-child(1)) {
    .content {
      opacity: 1;
      @for $i from 2 through 6 {
        &:nth-child(#{$i}) {
          transform: rotate(-20deg * ($i - 1));
          transition: transform $easeOutCubic #{(.25s / 6) * (6 - $i)}, opacity $easeOutCubic .1s;
        }
      }
    }
  }
}