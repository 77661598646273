@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

$desktopGutter: $spacing-xl;
$mobileGutter: $spacing-m;

  .scrollWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .scrollableContent {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: scroll;
      padding: 0;
      margin: 0;
      -ms-overflow-style: none; /* IE 11 */
      scrollbar-width: none; /* Firefox 64 */

      &::-webkit-scrollbar {
        display: none;
      }

      .content {
        overflow: hidden;
      }
    }

    .progressWrapper {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: $desktopGutter - 10px;
      width: 60px;
      top: 0;
      opacity: 1;
      pointer-events: auto;
      transition: opacity 0.25s $easeInOutCubic, transform 0.25s $easeOutCubic;
      transform: translate3d(0, 0px, 0);
      transform-origin: center 20%;
      
      @include device-m {
        right: $mobileGutter - 10px;
      }

      &.hidden {
        opacity: 0;
        pointer-events: none;
        transform: translate3d(0, -20px, 0);
        transition: opacity 0.25s $easeInOutCubic, transform 0.25s $easeInCubic;
      }

      @include device-m {
        pointer-events: none;
      }
    }

    @include device-m {
      .progressWrapper {
        right: 20px;
      }
    }
  }

.scrollProgress {
  cursor: grab;
  &.grabbing {
    cursor: grabbing;
  }
  .svg {
    overflow: visible;
  }
}