@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $spacing-m $spacing-xl;
  border: 1px solid $color-blue;
  // margin: $spacing-ml 0 0;
  // mix-blend-mode: difference;
  border-radius: 50%;
  transition: opacity 0.25s $easeInOutCubic;
  @include type_button;
  
  @include device-m {
    padding: $spacing-m $spacing-l;
  }

  .copy {
    transition: opacity 0.25s $easeInOutCubic;
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  
  &.small {
    padding: $spacing-s/2 $spacing-l;
    @include type_button_s;
  }

  &:hover {
    background-color: $color-blue;
  }

  .loader {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    // border: 3px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    // position: relative;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    border: 3px solid;
    animation: buttonSpinnerRotation 1s linear infinite;
    transition: opacity 0.25s $easeInOutCubic;
  }

  &.loading {
    pointer-events: none;
    .copy {
      opacity: 0;
    }
    .loader {
      opacity: 1;
    }
  }
}


@keyframes buttonSpinnerRotation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
} 