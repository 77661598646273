@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.wrapper {
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: 100%;

  .expandOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: grab;

    &.grabbing {
      cursor: grabbing;
    }
    
    .slide {
      position: relative;
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .slideContents {
        width: 100%;
        height: 100%;
      }
    }
  }

  .discs {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $spacing-ml;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: $layer-3;

    .background {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
      z-index: $layer-1;
      pointer-events: none;
    }

    .disc {
      margin: 4px;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: white;
      opacity: 0.4;
      transition: opacity 0.25s $easeInOutCubic;
      z-index: $layer-2;
      cursor: pointer;
      &.active {
        opacity: 1;
      }
      &:hover {
        opacity: 0.7;
      }

      @include device-m {
        margin-bottom: $spacing-m;
      }
    }
  }
}