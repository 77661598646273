@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.container {
  position: absolute;
}

.content {
  position: absolute;
  // transition: box-shadow 0.25s $easeInOutCubic;
  // box-shadow: none;
  &.onTop {
    // transition: box-shadow 3s $easeOutCubic;
    // box-shadow: 0 0 200px 0 white;
  }

  .title {
    display: block;
    @include type_title;
    padding-left: 10%;
    margin-bottom: $spacing-s;
  }

  .label {
    display: block;
    @include type_label;
    margin-bottom: $spacing-s;
    padding-left: 10%;
  }

  .copy {
    // position: absolute;
    // top: 0;
    // left: 0; 
    // width: 100%;
    // height: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: flex-start;
    @include type_par;
    padding: $spacing-m $spacing-l;
  }

  .buttons {
    position: absolute;
    width: 160px;
    height: 70px;
    right: $spacing-m;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: $layer-3;
    transform: rotate(10deg);
    // opacity: 0;
    pointer-events: none;

    .button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      border: 5px solid $color-darkgrey;
      cursor: pointer;
      opacity: 0;
      
      transition-duration: 0.25s, 0.25s;
      transition-timing-function: $easeInOutCubic;
      transition-delay: 0s, 0.5s;

      transform: scale(0.6) rotate(-60deg);
      
      &:nth-child(2) {
        transform: scale(0.6) rotate(80deg);
        transition-delay: 0.1s, 0.6s;
      }

      svg {
        pointer-events: none;
        path {
          fill: $color-darkgrey;
        }
      }

      &:hover {
        background-color: $color-blue !important;
        color: $color-white !important;
        svg {
          path {
            fill: $color-white !important;
          }
        }
      }

      .iconWrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.35s $easeOutBack 0s;
      }

      svg {
        position: relative;
        width: 60%;
        height: 60%;
      }
    }

    &.active {
      pointer-events: auto;
      .button {
        opacity: 1;
        transition: opacity 0.25s $easeInOutCubic 0.25s, transform 0.35s $easeOutBack 0.25s;
        transform: scale(1) rotate(-10deg);

        &:hover {
          // transition: opacity 0.25s $easeInOutCubic 0s, transform 0.35s $easeOutBack 0s;
          // transform: scale(1) rotate(-20deg);
          .iconWrapper {
            transform: scale(1) rotate(-20deg);
          }
        }

        &:nth-child(2) {
          transition: opacity 0.25s $easeInOutCubic 0.35s, transform 0.35s $easeOutBack 0.35s;
          transform: scale(1) rotate(15deg);

          &:hover {
            // transition: opacity 0.25s $easeInOutCubic 0s, transform 0.35s $easeOutBack 0s;
            // transform: scale(1) rotate(25deg);
            .iconWrapper {
              transform: scale(1) rotate(25deg);
            }
          }
        }

      }
    }
  }
}