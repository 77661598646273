@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/components.scss';
@import '../../styles/type.scss';

.grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
    
  .left {}
  .middle {}
  .right {}

  .resetButton {
    position: absolute;
    top: 0;
    right: 0;
    @include circleButton('big');
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: $spacing-m $spacing-m;
    height: 100%;
    .title {
      @include type_title;
      margin-bottom: $spacing-s;
      line-height: 30px;
    }
    p {
      @include type_par;
    }
  }

  .mintContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 $spacing-m $spacing-l;
    // height: 100%;
    .title {
      @include type_title;
      margin: $spacing-xl 0 $spacing-l;
      line-height: 30px;
    }
    p {
      @include type_par;
    }
  }
}