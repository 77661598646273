@mixin circleButton($size: 'small') {
  position: absolute;
  top: -30px;
  right: -30px;
  z-index: $layer-2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid $color-darkgrey;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  
  transition-duration: 0.25s, 0.25s;
  transition-timing-function: $easeInOutCubic;
  transition-delay: 0s, 0.5s;

  transform: scale(0.6) rotate(-60deg);

  @if $size == 'big' {
    top: -50px;
    right: -50px;
    width: 100px;
    height: 100px;
    // border: 10px solid $color-darkgrey;
  }

  > svg {
    transition: transform 0.35s $easeOutBack 0s;
  }

  .iconWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.35s $easeOutBack 0s;
    
    svg {
      pointer-events: none;
      position: relative;
      width: 50%;
      height: 50%;
    }
  }

  &.active {
    pointer-events: auto;
    opacity: 1;
    transition: opacity 0.25s $easeInOutCubic 0.25s, transform 0.35s $easeOutBack 0.25s;
    transform: scale(1) rotate(-10deg);

    &:hover {
      background-color: $color-blue !important;
      color: $color-white !important;
      svg {
        transform: rotate(-20deg);
        path {
          stroke: $color-white !important;
          fill: $color-white !important;
        }
      }
    }

    &:active {
      .iconWrapper {
        transform: rotate(50deg);
      }
    }
  }
}