@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .mask {
    position: absolute;
    cursor: default;
  }

  .svg {
    position: absolute;
    overflow: visible;

    .sliderPath {
      cursor: grab;
    }

    .arrow {
      pointer-events: none;
    }
  }

  &.grabbing {
    .svg {
      .sliderPath {
        cursor: grabbing;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    .svg {
      .sliderPath {
        cursor: pointer;
      }
    }
  }

}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $layer-3;
  pointer-events: none;
  opacity: 0;
}