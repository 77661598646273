@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.wrapper {
  position: absolute;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  padding-top: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // z-index: 999999;
  // pointer-events: none;

  .colorCanvas {
    width: 20%;
    height: auto;
    position: absolute;
    bottom: 50%;
    left: 50%;
  }

  .glslCanvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
