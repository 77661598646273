@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/components.scss';
@import '../../styles/type.scss';

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // flex-direction: column;
    @include type_title;

    .left, .right {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      flex-grow: 0;
      // width: 50%;
      // height: 100%;
    }

    .body {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      width: 100%;
      height: 100%;
      @include type_par;
      padding: $spacing-ml $spacing-ml $spacing-ml 0;

      .generateBtn {
        @include type_button_s;
        // margin-top: $spacing-m;
        margin: 0 auto;
      }
    }
  }
}

.bottom {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  justify-content: flex-start;
  flex-direction: column;

  @include device-m {
    // margin: 0 $spacing-m;
    position: relative;
    margin: 0 auto;
  }

  .button {
    @include circleButton();
    svg {
      position: absolute;
    }
  }

  .thumb {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;
    flex-grow: 0;
    width: 90%;
    height: calc(100% - 190px);

    .inlineSVG,
    .wrap {
      display: flex;
      position: relative;
      aspect-ratio: 1536 / 960;
      // display: block;
      flex-grow: 0;
      // flex-shrink: 0;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      align-items: center;
      justify-content: center;
      margin: auto;
      width: 100%;

      .shuffleWrapper {
        position: absolute;
        height: 100%;
        aspect-ratio: 1536 / 960;
      }

      > svg {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 100%;
        // max-width: 100%;
        // height: auto;
      }
    }
  }

  .buttons {
    height: 100px;
    width: 100px;
    background: $color-blue;
    margin: $spacing-m 0 100px 0;
    flex-shrink: 0;
  }
}