@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.pathWrapper {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  overflow: visible;
  // pointer-events: none;

  .text {
    position: absolute;
    opacity: 0; 
    white-space: nowrap;
    pointer-events: none;
    @include type_par_xl();
    line-height: 22px;
  }

  .svg {
    // position: absolute;
    // top: 0px;
    // left: 0px;
    // width: 100%;
    // height: 100%;
    z-index: 111;
    // overflow: hidden;
    overflow: visible;

    .path {
      // pointer-events: none;
      // opacity: 0;
    }

    .label {
      @include type_par_xl();
      // pointer-events: none;
      color: white;
      line-height: 22px;
      transform: translate3d(0, 2px, 0);
      
      // &.isLink {
        //   pointer-events: auto;
        // }
        
      &.hover {
        cursor: pointer;
        font-variation-settings: 'wght' 400;
      }
  
    }

    &.hover {
      .path:nth-child(2) {
        opacity: 1;
      }
      .label {
        // text-decoration: underline;
        // font-weight: 400;
        font-variation-settings: 'wght' 400;
      }
    }
  }
}