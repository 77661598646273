@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';
@import '../../styles/type.scss';

.pathWrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // pointer-events: none;

  .text {
    position: absolute;
    opacity: 0; 
    white-space: nowrap;
    pointer-events: none;
    @include type_title();
    @include device-m {
      font-size: 22px;
    }
  }

  .svg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 111;
    overflow: hidden;

    .path {
      pointer-events: none;
      // opacity: 0;
    }

    .label {
      @include type_title();
      // pointer-events: none;
      color: white;
      cursor: pointer;
  
      // &.isLink {
      //   pointer-events: auto;
      // }
      @include device-m {
        font-size: 22px;
      }
  
    }

    // @include device-m {
    //   .path:nth-child(2) {
    //     opacity: 1;
    //   }
    // }

    &.hover {
      .path:nth-child(2) {
        stroke: blue !important;
      }
      .label {
        // text-decoration: underline;
        // font-weight: 400;
        // color: white !important;
        font-variation-settings: 'wght' 400;
      }
    }
  }
}